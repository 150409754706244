// Fonts
import "@fontsource-variable/outfit";
import "@fontsource/poppins";
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
// Components
import MainPage from "./views/MainPage/MainPage";
import NavBar from "./components/navbar/NavBar";
// Stylesheets
import "./App.scss";

function App() {
  const appCtx = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(".navbar-title, .navbar-item, .navbar-pop-item", {
        color: "#FFF",
        duration: 0.5,
        scrollTrigger: {
          trigger: "#contact",
          start: "top 0%",
          end: "top 0%",
          toggleActions: "play none reverse none",
        },
      });
      gsap.to(".menu-btn", {
        filter: "invert(1)",
        duration: 0.5,
        scrollTrigger: {
          trigger: "#contact",
          start: "top 0%",
          end: "top 0%",
          toggleActions: "play none reverse none",
        },
      });
    }, appCtx);
    return () => ctx.revert();
  }, []);

  return (
    <div className="App" ref={appCtx}>
      <NavBar />
      <MainPage />
    </div>
  );
}

export default App;
