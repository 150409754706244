import { useContext, useEffect } from "react";
// Contexts
import NavContext from "../../contexts/NavContext";
// Components
import About from "./components/About";
import Home from "./components/Home";
import Project from "./components/Project";
import Contact from "./components/Contact";
// Stylesheet
import "./styles/mainPage.scss";

function MainPage() {
  const { setCurrYOffset } = useContext(NavContext);

  useEffect(() => {
    const handleScroll = () => {
      setCurrYOffset(window.pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setCurrYOffset]);

  return (
    <div id="main-page">
      <Home />
      <About />
      <Project />
      <Contact />
    </div>
  );
}

export default MainPage;
