export const SELF_PROJECTS = [
  {
    id: "gsp",
    name: "GamerStop E-Store",
    caption: ["💡  Personal Project"],
    basicImg: "personal/gamerstop/gs_product.png",
    imgScrollable: false,
    description:
      "An online platform for merchandising online gaming products, which includes features such as a shopping cart, order management, multi-language support, and a dark/light theme.",
    link: [
      {
        name: "Code Repo",
        url: "https://github.com/sherwxcc/React18-GameStore",
      },
    ],
    feature: [
      "React",
      "Express",
      "MySQL",
      "Knex",
      "MUI",
      "i18next",
      "SASS",
      "Styled Components",
    ],
  },
  {
    id: "tdl",
    name: "To Do List",
    caption: ["💡  Personal Project"],
    basicImg: "personal/todolist/td_landing.png",
    imgScrollable: false,
    description:
      "A React To-Do List that includes backend storage. In addition to basic CRUD operations and user authentication, it also offers search functions, along with a responsive UI.",
    link: [{ name: "Code Repo", url: "https://github.com/sherwxcc/ToDoList" }],
    feature: ["React", "Express", "PostgreSQL", "Knex", "JWT"],
  },
];

export const SCHOOL_PROJECTS = [
  {
    id: "appsec",
    name: "Application Security Demo",
    caption: ["🎓  School Project"],
    basicImg: "school/appsec/appsec_landing.png",
    imgScrollable: false,
    description:
      "A simulation on the study of the Bumble web application, along with suggested improvements to app security through the use of dynamic HMAC.",
    link: [
      {
        name: "Code Repo",
        url: "https://github.com/tcyeung1128/securityProject",
      },
    ],
    feature: ["React", "API Security", "HMAC", "Cybersecurity"],
  },
  {
    id: "des",
    name: "New DES Scheme Demo",
    caption: ["🎓  School Project"],
    basicImg: "school/newdes/newdes_landing.png",
    imgScrollable: false,
    description:
      "A demo of a encryption algorithm that is based on DES and 3DES, with new transition steps added to increase complexity.",
    link: [
      {
        name: "Code Repo",
        url: "https://github.com/sherwxcc/New-DES-Scheme-Demo",
      },
    ],
    feature: ["React", "DES Algorithm", "Cybersecurity"],
  },
];

export const WORK_PROJECTS = [
  {
    id: "snapdefi",
    name: "SnapEx DeFi",
    caption: ["💼  Full Stack Developer", "📍  ThetaTech · Jan-Oct 2022"],
    basicImg: "work/snapdefi/sd_landing.png",
    imgScrollable: false,
    description:
      "A DeFi platform that focuses on cryptocurrency staking and offers decentralized launchpad services, along with fund dashboards for easy tracking.",
    link: [{ name: "SnapEx DeFi", url: "https://snapexdefi.com/dashboard" }],
    feature: ["React", "Web3.JS", "Blockchain", "Crypto", "BSC"],
  },
  {
    id: "aptoslaunch",
    name: "Aptos Launch (2022)",
    caption: ["💼  Full Stack Developer", "📍  ThetaTech · Jan-Oct 2022"],
    basicImg: "work/aptoslaunch/scrollable/al_scroll.png",
    imgScrollable: true,
    description:
      "A DeFi platform that focuses on cryptocurrency launchpad services, specifically for a new chain.",
    link: [],
    feature: ["React", "Web3.JS", "Blockchain", "Crypto", "Aptos"],
  },
  {
    id: "snapex",
    name: "SnapEx Trading Platform (2022)",
    caption: ["💼  Full Stack Developer", "📍  ThetaTech · Jan-Oct 2022"],
    basicImg: "work/snaptrade/scrollable/st_scroll_web.png",
    imgScrollable: true,
    description:
      "A revamp of the frontend of a cryptocurrency CFD trading platform, which includes upgrading to a responsive design and performing maintenance on legacy code.",
    link: [],
    feature: ["Vue", "VueX", "ElementUI"],
  },
  {
    id: "daodefi",
    name: "Daorex DeFi (2022)",
    caption: ["💼  Full Stack Developer", "📍  ThetaTech · Jan-Oct 2022"],
    basicImg: "work/daodefi/dd_landing.png",
    imgScrollable: false,
    description:
      "A DeFi platform that focuses on cryptocurrency staking and token swapping, with features such as a personal dashboard and profit calculator.",
    link: [],
    feature: ["React", "Web3.JS", "Blockchain", "Crypto"],
  },
];

export const PROJECT_LIST =
  WORK_PROJECTS.concat(SELF_PROJECTS).concat(SCHOOL_PROJECTS);
