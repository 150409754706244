import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// Contexts
import NavContext from "../../../contexts/NavContext";
// Stylesheet
import "../styles/about.scss";

function About() {
  gsap.registerPlugin(ScrollTrigger);
  const { setAboutOffset } = useContext(NavContext);
  const aboutCtx = useRef();

  useEffect(() => {
    setAboutOffset(aboutCtx.current.offsetTop);
  }, [aboutCtx, setAboutOffset]);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from("[data-gsap='slide-in-left']", {
        xPercent: -100,
        duration: 1.5,
        opacity: 0,
        scrollTrigger: {
          trigger: "[data-gsap='slide-in-left']",
          toggleAction: "play none none none",
          start: "top 70%",
        },
      });
      gsap.fromTo(
        "[data-gsap='fade-in-out']",
        { opacity: 0, scale: 0.4 },
        {
          opacity: 1,
          scale: 1.05,
          duration: 0.5,
          delay: 1,
          stagger: 0.4,
          scrollTrigger: {
            trigger: "[data-gsap='slide-in-left']",
            toggleAction: "play none none none",
            start: "top 70%",
            once: true,
          },
        }
      );
      gsap.from("[data-gsap='fade-in-out']", {
        yPercent: -10,
        duration: 3,
        yoyo: true,
        ease: "power1.inOut",
        repeat: -1,
        stagger: 1,
      });
      gsap.from("[data-gsap='slide-in-right']", {
        x: "100%",
        duration: 1.5,
        opacity: 0,
        scrollTrigger: {
          trigger: "[data-gsap='slide-in-right']",
          toggleAction: "play none none none",
          start: "top 80%",
        },
      });
      gsap.to("#about-view", {
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: "#about-view",
          start: "bottom 30%",
          end: "bottom 5%",
          toggleAction: "play none reverse none",
          scrub: true,
        },
      });
    }, aboutCtx);
    return () => ctx.revert();
  }, []);

  const handleScaleUp = (e) => {
    gsap.to(e.currentTarget, {
      scale: 1.03,
      duration: 0.5,
      ease: "power1.inOut",
    });
  };

  const handleScaleDown = (e) => {
    gsap.to(e.currentTarget, {
      scale: 1,
      duration: 0.5,
      ease: "power1.inOut",
    });
  };

  return (
    <section id="about" ref={aboutCtx}>
      <div id="about-view">
        {/* Section: About Me */}
        <div className="about-section first">
          <div
            className="about-me-text glassy-card"
            data-gsap="slide-in-left"
            onMouseEnter={(e) => handleScaleUp(e)}
            onMouseLeave={(e) => handleScaleDown(e)}
          >
            <h4 className="font-outfit color-purple">ABOUT ME</h4>
            <h3 className="font-outfit">
              DILIGENT, FAST-LEARNING AND INQUISITIVE{" "}
              <span className="sp-text-gradient">WEB DEVELOPER</span> 🚀
            </h3>
            <p>
              A proactive and fast-learning{" "}
              <span className="sp-text-green">Full Stack Web Developer</span>,
              specialized in{" "}
              <span className="sp-text-green">Front-End Development</span>. I
              have experience in designing and developing fully responsive
              crypto financial products and crypto-trading platform in{" "}
              <span className="color-purple">React.JS</span> and{" "}
              <span className="color-green">Vue.JS</span>. Currently seeking
              changes and new challenges in professional growth.
            </p>
          </div>
          <div className="about-me-graphic">
            <img
              src={require("../../../assets/icons/vue_named.png")}
              alt="VueJS"
              className="floating2"
              data-gsap="fade-in-out"
            />
            <img
              src={require("../../../assets/icons/react_named.png")}
              alt="ReactJS"
              className="floating1"
              data-gsap="fade-in-out"
            />
            <img
              src={require("../../../assets/icons/web3.png")}
              alt="Web3"
              className="floating3"
              data-gsap="fade-in-out"
            />
          </div>
        </div>
        {/* Section: Skill Set */}
        <div className="about-section last">
          {/* <div className="about-me-graphic">
            <p>Space for images</p>
          </div> */}
          <div
            className="about-me-text glassy-card"
            data-gsap="slide-in-right"
            onMouseEnter={(e) => handleScaleUp(e)}
            onMouseLeave={(e) => handleScaleDown(e)}
          >
            <h4 className="font-outfit color-green">SKILL SET</h4>
            <h3 className="font-outfit">
              MULTI-TALENTED, DIVERSE AND COLLABORATIVE{" "}
              <span className="sp-text-gradient">TEAM PLAYER</span> 🤝
            </h3>
            <p>
              As a Junior Developer, I have experience in coding with a variety
              of{" "}
              <span className="sp-text-purple">
                Web Development Technologies
              </span>
              . Including RDBMS such as{" "}
              <span className="color-green">PostgreSQL</span>,{" "}
              <span className="color-green">MySQL</span>,{" "}
              <span className="color-green">OracleDB</span>, backend frameworks
              such as <span className="color-green">Express.JS</span>, styling
              libraries such as <span className="color-green">Bootstrap</span>,{" "}
              <span className="color-green">MUI</span>,{" "}
              <span className="color-green">styled-components</span>, and other
              tools like <span className="color-green">jQuery</span>,{" "}
              <span className="color-green">Web3.JS</span> etc.
            </p>
            <p>
              I am always eager to{" "}
              <span className="sp-text-purple">learn and adapt</span> into
              different projects with different technical requirements, and I am
              confident to quickly pick up new technologies and integrate them
              into my work.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
