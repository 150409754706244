import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// Contexts
import NavContext from "../../../contexts/NavContext";
// Components
import ProjectDetail from "./ProjectDetail";
// Constants
import { BREAK_POINTS } from "../../../constants/animDefault";
import { PROJECT_LIST } from "../../../constants/projects";
// Stylesheet
import "../styles/project.scss";

function Project() {
  gsap.registerPlugin(ScrollTrigger);

  const projectCtx = useRef();
  const { setProjectOffset } = useContext(NavContext);

  useEffect(() => {
    setProjectOffset(projectCtx.current.offsetTop);
  }, [projectCtx, setProjectOffset]);

  useLayoutEffect(() => {
    const mm = gsap.matchMedia(projectCtx);
    mm.add(
      BREAK_POINTS,
      (context) => {
        let { isMediSmall, isMedium, isLarge } = context.conditions;
        gsap.to("#project-gallery", {
          xPercent: isLarge ? -70 : isMedium ? -80 : isMediSmall ? -87 : -88,
          ease: "none",
          scrollTrigger: {
            trigger: "#project-scroll-container",
            start: "top 0%",
            scrub: true,
            pin: "#project-scroll-container",
            toggleActions: "play none reverse none",
          },
        });
      },
      projectCtx
    );
    return () => mm.revert();
  }, []);

  return (
    <section id="project" ref={projectCtx}>
      <div id="project-scroll-container">
        <div id="project-view">
          <div className="project-section">
            <h3 className="font-outfit section-heading">
              PROJECTS & EXPERIENCE
            </h3>
            {/* Projects */}
            <div className="project-list">
              <div id="project-gallery">
                <ProjectDetail projectList={PROJECT_LIST} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
