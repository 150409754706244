// Components
import Tag from "../../../components/tag/Tag";
import { BiLink } from "react-icons/bi";
import { PiCursorClickBold } from "react-icons/pi";

function ProjectDetail({ projectList }) {
  return (
    <>
      {projectList.map((el, i) => (
        <div
          className="project-detail glassy-card"
          key={el.name}
          data-gsap="scroll"
        >
          <div className="img-screen">
            <img
              src={require(`../../../assets/projects/${el.basicImg}`)}
              alt="Project Preview"
              className={`project-img ${el.imgScrollable ? "scrollable" : ""}`}
            />
            {el.imgScrollable ? (
              <div className="scroll-overlay">
                <span>Hover Me</span>
                <PiCursorClickBold color="#FFF" size="1.3rem" />
              </div>
            ) : null}
          </div>
          <div className="project-desc">
            <h4 className="font-outfit">
              <span className="sp-text-purple">{el.name}</span>
            </h4>
            <div className="project-cap">
              {el.caption
                ? el.caption.map((cap, i) => (
                    <p className="color-green" key={i}>
                      {cap}
                    </p>
                  ))
                : null}
            </div>
            <p className="project-account">{el.description}</p>
            {el.link.length ? (
              el.link.map((el, i) => (
                <p className="github color-green" key={i}>
                  <a href={el.url} target="_blank" rel="noreferrer">
                    <BiLink />
                    {el.name}
                  </a>
                </p>
              ))
            ) : (
              <br />
            )}
            {el.feature.map((el, i) => (
              <Tag tagname={el} key={i} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default ProjectDetail;
