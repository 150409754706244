import { useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
// Constants
import { BREAK_POINTS } from "../../constants/animDefault";
// Stylesheet
import "./navbar.scss";

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navRef = useRef();

  useLayoutEffect(() => {
    const mm = gsap.matchMedia(navRef);
    mm.add(
      BREAK_POINTS,
      (context) => {
        let { isLarge } = context.conditions;
        gsap.to(".navbar-item", {
          x: !isLarge ? 130 : 0,
          opacity: !isLarge ? 0 : 1,
        });
        gsap.to(".menu-btn", {
          x: !isLarge ? 0 : 130,
          opacity: !isLarge ? 1 : 0,
          onComplete: () => {
            setIsMenuOpen(false);
          },
        });
      },
      navRef
    );
    return () => mm.revert();
  }, []);

  const handleMenuPop = () => {
    gsap.from(".nav-menu-popup", {
      opacity: isMenuOpen ? 1 : 0,
      duration: 0.7,
    });
  };

  return (
    <>
      <section id="navbar" ref={navRef}>
        <div>
          <div>
            <h1 className="navbar-title">
              <a href="#home">Sherman.webdev</a>
            </h1>
          </div>
          <div className="navbar-menu">
            <>
              <div className="navbar-item">
                <a href="#home">Home</a>
              </div>
              <div className="navbar-item">
                <a href="#about">About</a>
              </div>
              <div className="navbar-item">
                <a href="#project">Projects</a>
              </div>
              <div className="navbar-item">
                <a href="#contact">Contact</a>
              </div>
            </>
            <img
              className={`menu-btn ${isMenuOpen ? "close" : null}`}
              alt="Nav Menu"
              src={
                isMenuOpen
                  ? require("../../assets/icons/close.png")
                  : require("../../assets/icons/menu.png")
              }
              onClick={() => {
                setIsMenuOpen((prev) => !prev);
                handleMenuPop();
              }}
            />
            <div
              className={`nav-menu-popup glassy-card ${
                isMenuOpen ? "open" : null
              }`}
            >
              <h4 className="navbar-pop-item">
                <a href="#home" onClick={() => setIsMenuOpen(false)}>
                  Home
                </a>
              </h4>
              <h4 className="navbar-pop-item">
                <a href="#about" onClick={() => setIsMenuOpen(false)}>
                  About
                </a>
              </h4>
              <h4 className="navbar-pop-item">
                <a href="#project" onClick={() => setIsMenuOpen(false)}>
                  Project
                </a>
              </h4>
              <h4 className="navbar-pop-item">
                <a href="#contact" onClick={() => setIsMenuOpen(false)}>
                  Contact
                </a>
              </h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NavBar;
