import { useForm, ValidationError } from "@formspree/react";

function ContactForm() {
  const [state, handleSubmit] = useForm("mwkdkqrn");

  if (state.succeeded) {
    return (
      <div className="success-msg">
        <h3 className="color-white">
          Thanks for your message ! I will get back to you very soon 🍻
        </h3>
      </div>
    );
  }

  return (
    <div id="contact-form">
      <h3 className="color-white font-outfit title">LEAVE A MESSAGE 💬</h3>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email" className="color-white">
          Email Address
        </label>
        <input id="email" type="email" name="email" />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <label htmlFor="message" className="color-white">
          Your Message
        </label>
        <textarea id="message" name="message" />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <br />
        <button
          type="submit"
          disabled={state.submitting}
          className="font-outfit color-white"
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
