import { useContext, useEffect, useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// Contexts
import NavContext from "../../../contexts/NavContext";
// Images
import CssIcon from "../../../assets/icons/css.png";
import HtmlIcon from "../../../assets/icons/html.png";
import JsIcon from "../../../assets/icons/js.png";
import NodeIcon from "../../../assets/icons/nodejs.png";
import ReactIcon from "../../../assets/icons/react.png";
import SassIcon from "../../../assets/icons/sass.png";
import SqlIcon from "../../../assets/icons/mysql.png";
import { CgScrollV } from "react-icons/cg";
// Stylesheet
import "../styles/home.scss";

function Home() {
  gsap.registerPlugin(ScrollTrigger);
  const { setHomeOffset } = useContext(NavContext);

  const homeCtx = useRef();
  const homeTl = useRef();

  useEffect(() => {
    setHomeOffset(homeCtx.current.offsetTop);
  }, [homeCtx, setHomeOffset]);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      homeTl.current && homeTl.current.progress(0).kill();
      homeTl.current = gsap
        .timeline()
        .set("[data-gsap='fade-in-out']", { opacity: 0 })
        .from("[data-gsap='fade-slide-in']", {
          y: "+=50",
          duration: 1.5,
          opacity: 0,
          stagger: 0.3,
        })
        .to("[data-gsap='fade-in-out']", { opacity: 1, duration: 1 }, "+=0.5");
      gsap.fromTo(
        ["[data-gsap='fade-in-out']"],
        { opacity: 1 },
        {
          opacity: 0,
          duration: 3,
          scrollTrigger: {
            trigger: "[data-gsap='fade-in-out']",
            toggleActions: "play none reverse none",
            start: "top 90%",
            end: "bottom 20%",
            scrub: true,
          },
        }
      );
    }, homeCtx);

    return () => ctx.revert();
  }, []);

  return (
    <section id="home" ref={homeCtx}>
      <div id="home-view-top">
        {/* Headline */}
        <div>
          <h2 className="font-outfit hero-title" data-gsap="fade-slide-in">
            <span>Full Stack Web Developer </span>
            &#x1F469;&#x1F3FB;&#x200D;&#x1F4BB;
          </h2>
          <h4 data-gsap="fade-slide-in">
            Hi There! I'm Sherman &#x1F44B;&#x1F3FB; A Full Stack Web Dev Based
            in Hong Kong &#x1F1ED;&#x1F1F0;
          </h4>
        </div>
        {/* Tech stack */}
        <div className="content-skill" data-gsap="fade-slide-in">
          <div className="font-outfit">Tech Stack</div>
          <div className="icon-set" data-gsap="mm-flex-col">
            <div className="icon-container">
              <img src={HtmlIcon} alt="HTML5" className="skill-icon" />
              <div className="skill-caption">HTML</div>
            </div>
            <div className="icon-container">
              <img src={CssIcon} alt="CSS3" className="skill-icon" />
              <div className="skill-caption">CSS</div>
            </div>
            <div className="icon-container">
              <img src={JsIcon} alt="JavaScript" className="skill-icon" />
              <div className="skill-caption">JS</div>
            </div>
            <div className="icon-container">
              <img src={ReactIcon} alt="React.JS" className="skill-icon" />
              <div className="skill-caption">React</div>
            </div>
            <div className="icon-container">
              <img src={NodeIcon} alt="Node.JS" className="skill-icon" />
              <div className="skill-caption">Node</div>
            </div>
            <div className="icon-container">
              <img src={SqlIcon} alt="SQL" className="skill-icon" />
              <div className="skill-caption">SQL</div>
            </div>
            <div className="icon-container">
              <img src={SassIcon} alt="SASS" className="skill-icon" />
              <div className="skill-caption color-black">SASS</div>
            </div>
          </div>
        </div>
      </div>
      {/* Scroll Hint */}
      <div id="home-view-bot" data-gsap="fade-in-out">
        <CgScrollV size="1.3rem" color="#808080" />
      </div>
    </section>
  );
}

export default Home;
