// Images
import { RxExternalLink } from "react-icons/rx";
// Components
import ContactForm from "./ContactForm";
// Stylesheet
import "../styles/contact.scss";

function Contact() {
  return (
    <section id="contact">
      <div id="contact-view">
        <div className="contact-card">
          <div className="card-flex">
            <div className="contact-list">
              <h3 className="color-white font-outfit title">CONTACT 📨</h3>
              <h4 className="color-white">
                GitHub :{" "}
                <a
                  href="https://github.com/sherwxcc"
                  target="_blank"
                  rel="noreferrer"
                  className="ex-link"
                >
                  sherwxcc <RxExternalLink className="pos-adjust" />
                </a>
              </h4>
              <h4 className="color-white">
                Email :{" "}
                <a
                  href="mailto:shermanw613@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="ex-link"
                >
                  shermanw613@gmail.com
                </a>
              </h4>
            </div>
            <ContactForm />
          </div>
        </div>
        <div className="ack color-white">
          <p>
            © Sherman Wong 2023 · Built with{" "}
            <a
              href="https://react.dev/"
              rel="noreferrer"
              target="_blank"
              className="ex-link"
            >
              React
            </a>
            ,{" "}
            <a
              href="https://sass-lang.com/"
              rel="noreferrer"
              target="_blank"
              className="ex-link"
            >
              SASS
            </a>{" "}
            and{" "}
            <a
              href="https://greensock.com/gsap/"
              rel="noreferrer"
              target="_blank"
              className="ex-link"
            >
              GSAP
            </a>{" "}
            · Deployment with{" "}
            <a
              href="https://www.cloudflare.com/"
              rel="noreferrer"
              target="_blank"
              className="ex-link"
            >
              Cloudflare
            </a>{" "}
            · Icons from{" "}
            <a
              href="https://www.flaticon.com/"
              rel="noreferrer"
              target="_blank"
              className="ex-link"
            >
              Flaticon
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Contact;
