import "./tag.scss";

function Tag({ tagname }) {
  return (
    <>
      <span className="tag">{tagname}</span>
    </>
  );
}

export default Tag;
