import { createContext, useEffect, useState } from "react";

const NavContext = createContext();

export function NavProvider({ children }) {
  const [activeNavItem, setActiveNavItem] = useState("home");
  const [currYOffset, setCurrYOffset] = useState(window.scrollY);
  const [aboutOffset, setAboutOffset] = useState(null);
  const [homeOffset, setHomeOffset] = useState(null);
  const [projectOffset, setProjectOffset] = useState(null);

  useEffect(() => {
    if (currYOffset >= projectOffset * 0.7) {
      setActiveNavItem("project");
    } else if (currYOffset >= aboutOffset * 0.7) {
      setActiveNavItem("about");
    } else {
      setActiveNavItem("home");
    }
  }, [currYOffset, aboutOffset, homeOffset, projectOffset]);

  return (
    <NavContext.Provider
      value={{
        activeNavItem,
        setCurrYOffset,
        setAboutOffset,
        setHomeOffset,
        setProjectOffset,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}

export default NavContext;
